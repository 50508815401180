.bg {
	background: var(--colorGreenLight) url(../../../images/grass.png);
	background-size: 33%;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.message {
	font-size: var(--fontSizeMid);
	margin: 0 var(--spacingMedium) var(--spacingMedium);
	line-height: 1.2;
}

.bootButton {
	margin: 0 auto var(--spacingMedium);
}

.skipButton {
	margin: 0 auto var(--spacingLarge);
}

.steps {
	background-color: rgba(0, 0, 0, 0.3);
	width: 188px;
	height: 3px;
	margin: auto auto var(--spacingMedium);

	& > div {
		background-color: var(--colorBlack);
		height: 3px;
	}
}

.btnMargin {
	margin-bottom: var(--spacingMedium);
}

.thumb {
	background-color: var(--colorWhite);
	border-radius: 1.2em;
	border-image: url(../../../images/border--normal.png) 12 / 12px round;
	border-width: 12px;
	border-style: solid;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: -70px auto 1.5rem;
}

.requirementsText {
	font-family: var(--fontFamilyHeading);
	margin-bottom: var(--spacingMedium);
}
