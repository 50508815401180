@import 'reset';
@import 'variables';

/*fonts*/
@font-face {
	font-family: Retro Gaming;
	src: url(/assets/Retro-Gaming.woff2) format('woff2'),
		url(/assets/Retro-Gaming.woff) format('woff');
}

@font-face {
	font-family: rainyhearts;
	src: url(/assets/rainyhearts.woff2) format('woff2'),
		url(/assets/rainyhearts.woff) format('woff');
}

* {
	box-sizing: border-box;
	user-select: none;
}

body {
	font-family: var(--fontFamilyBase);
	font-size: var(--fontSizeMedium);
	margin: 0;
	background-color: var(--colorGrey900);

	@media screen and (min-width: 30rem) {
		position: relative;

		&:before {
			content: '';
			background: url(/images/desktop-bg.png), var(--colorBlack);
			background-repeat: no-repeat;
			background-size: cover;
			opacity: 0.5;
			position: fixed;
			inset: 0;
			z-index: -1;
		}
	}
}

button {
	font-family: inherit;
}

/* typography */
h1,
h2,
h3,
h4 {
	font-family: var(--fontFamilyHeading);
	margin-bottom: 0.5em;
}

h1 {
	font-family: var(--fontFamilyHeading);
	text-transform: uppercase;
	line-height: 1;
	font-size: var(--fontSizeMedium);
	color: var(--colorBlack);
	margin: 0;
}

h2 {
	text-transform: uppercase;
	font-size: var(--fontSizeMedium);
}

hr {
	margin-top: 30px;
	height: 0;
	background: none;
	border: none;
	border-bottom: 1px dotted black;
	margin-bottom: 30px;
}

p {
	font-size: var(--fontSizeMedium);
	line-height: var(--lineHeight);
	&:not(:last-child) {
		margin-bottom: var(--spacingMedium);
	}
}

/* misc */

.icon-header {
	margin-bottom: var(--spacingMedium);
	img {
		margin: 0 auto;
	}
}

.link-icon {
	display: inline-block;
	margin-right: var(--spacingSmall);
	vertical-align: sub;
}

.visually-hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
