.btn {
	color: var(--colorBlack);
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em 1.1em;
	line-height: 1.5;
	text-decoration: none;
	font-family: var(--fontFamilyHeading);
	text-transform: uppercase;
	width: 15rem;
	font-size: var(--fontSizeSmall);

	&:disabled {
		background-color: var(--colorGrey400);
		cursor: auto;

		> img,
		> div {
			filter: grayscale(100%);
			opacity: 0.5;
		}
	}

	border-image: url(/images/border--normal.png) 12 / 12px round;
	border-radius: 22px;

	&:not(:disabled):hover,
	&:not(:disabled):focus {
		border-image-source: url(/images/border--hover.png);
	}
}

.red {
	background-color: var(--colorBurgundy);
	color: var(--colorWhite);
}

.white {
	background-color: var(--colorWhite);
	color: var(--colorBlack);
}

.yellow {
	background-color: var(--colorYellowLight);
	color: var(--colorBlack);
}

.blue {
	background-color: var(--colorBlueDark);
	color: var(--colorWhite);
}

.transparent {
	background-color: transparent;
	border: 0;
	color: var(--colorGreyDark);
}

.buttonList {
	& > button:not(:last-child) {
		margin-bottom: var(--spacingSmall);
	}
}

.buttonRow {
	display: flex;

	*:not(:first-child) {
		margin-left: var(--spacingMedium);
	}
}
