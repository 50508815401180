.titlebar {
	position: relative;
	z-index: 10;
	padding: var(--spacingMedium);
	padding-top: calc(var(--spacingMedium) + 40px);
	margin-top: -40px;
	background-color: var(--colorWhite);
	border-image: url(/images/border--normal.png) 12 / 12px round;
	border-radius: 22px;
	border-style: solid;
}
