.stars {
	display: flex;
	justify-content: center;
	margin: 0 auto;

	& > img {
		width: 39px;
		height: 37px;
		&:not(:first-child) {
			margin-left: 5px;
		}
	}
}
