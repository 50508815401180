:root {
	--colorBlack: #000;
	--colorWhite: #fff;
	--colorGrey100: #f1f5f9;
	--colorGrey200: #e2e8f0;
	--colorGrey300: #cbd5e1;
	--colorGrey400: #aaa;
	--colorGrey500: #858585;
	--colorGrey600: #666;
	--colorGrey700: #333;
	--colorGrey900: #1c1917;
	--colorGreen: #30aa10;

	--colorGreenLight: #9ecb7b;
	--colorGreenLightRgb: 158, 203, 123;
	--colorRed: #aa1634;
	--colorRedLightRgb: 210, 32, 72;

	--colorBurgundy: #9b2743;
	--colorBlue: #33aaff;
	--colorBlueLight: #c7e3ed;
	--colorBlueMed: #4e81c1;
	--colorBlueDark: #3b95fe;
	--colorGreenDark: #35716c;
	--colorGreyInactive: #dfdfdf;
	--colorGreyDark: #2c3035;
	--colorYellowLight: #fff09e;
	--colorYellow: #ffdd1a;

	--colorBeach: #0764be;
	--colorCamping: #5f8541;
	--colorUni: #184754;
	--colorInLaw: #2f2c4b;

	--fontFamilyBase: rainyhearts;
	--fontFamilyHeading: Retro Gaming;

	--shadowSubtle: 0 2px 20px 0 rgba(--colorBlack, 0.05);
	--shadowSmall: 0 2px 20px 0 rgba(--colorBlack, 0.1);
	--shadowMedium: 0 2px 20px 0 rgba(--colorBlack, 0.12);
	--shadowLarge: 0 2px 30px 0 rgba(--colorBlack, 0.15);

	--fontSizeXSmall: 0.65rem;
	--fontSizeSmall: 1rem;
	--fontSizeMedium: 1.15rem;
	--fontSizeMid: 1.25rem;
	--fontSizeLarge: 1.5rem;
	--fontSizeXLarge: 2rem;

	--lineHeight: 1rem;

	--spacingSmall: 0.5rem;
	--spacingMedium: 1rem;
	--spacingLarge: 1.75rem;

	@media (min-height: 700px) {
		--fontSizeXSmall: 0.8125rem;
		--fontSizeSmall: 1.125rem;
		--fontSizeMedium: 1.25rem;
		--fontSizeMid: 1.5rem;
		--fontSizeLarge: 1.75rem;
		--fontSizeXLarge: 2.5rem;

		--lineHeight: 1.75rem;

		--spacingSmall: 0.8rem;
		--spacingMedium: 1.125rem;
		--spacingLarge: 2.25rem;
	}
}
