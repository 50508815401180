.levelSelect {
	background-color: var(--colorGrey600);
}

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	:nth-child(3) {
		width: 39px;
		height: 39px;
	}
}

.content {
	margin: var(--spacingSmall);
}

.levelWrapper {
	overflow: hidden;
	max-width: 25rem;
	margin: 0 auto var(--spacingMedium);
}

.levelTitle {
	border-image: url(/images/border--light.png) 12 / 12px 12px 0 12px;
	border-radius: 22px 22px 0 0;
	border-style: solid;
	color: var(--colorWhite);
	font-size: var(--fontSizeSmall);
	padding: var(--spacingMedium);
	padding-bottom: calc(var(--spacingMedium) - 0.3rem);
	margin: 0;
	line-height: 1;
	text-align: center;
}

.levelGrid {
	position: relative;
	border-top: 0;
	border-image: url(/images/border--light.png) 12 / 0 12px 12px 12px;
	border-radius: 0 0 22px 22px;
	border-style: solid;
	background-size: contain;
	display: grid;
	gap: var(--spacingSmall);
	grid-template-columns: repeat(4, 1fr);
	padding: var(--spacingSmall);

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		content: '';
		border-top: 3px solid var(--colorGrey700);
	}

	@media (min-width: 450px) {
		gap: var(--spacingMedium);
		padding: var(--spacingMedium);
	}
}

.levelItem {
	position: relative;
	padding-top: 100%;

	& > button {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--colorWhite);
		border-image: url(/images/border--small.png) 9 / 9px;
		border-radius: 16px;
		color: var(--colorGreenDark);
		font-family: var(--fontFamilyHeading);
		font-size: var(--fontSizeMedium);
		cursor: pointer;

		&[disabled] {
			background-color: var(--colorGreyInactive);
			color: var(--colorGrey600);
			cursor: default;
		}
	}
}

.itemStars {
	bottom: -4px;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	width: 75%;

	> img {
		height: auto;
		width: calc(100% / 3);
	}
}
