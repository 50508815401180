.blocks {
    &Row{
        display: flex;
    }

    &Cell {
        width: 12px;
        height: 12px;
        margin: 1px;
        background-color: lightgrey;

        &On {
            background-color: black;
        }
    }
}

.items {
    width: 100%;
}

.form {
    padding: 2rem;
    input {
        width: 100%;
        margin-bottom: 2rem;
    }
    select {
        height: 2rem;
        width: 90%;
    }
}

.itemList {
    margin-bottom: 2rem;

    &Item {
        display: flex;
        align-items: center;
        padding: 5px;
        background-color: white;
        border-bottom: 1px dotted grey;
    
        & > div:first-child {
            margin-right: auto;
        }
    
        > button {
            width: 10%;
            margin-left: 2%;
        }
    }
}

.board {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    background-color: white;
    border: 1px dotted grey;
    margin: 0 1em 3rem;
}

.shapes {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem;

    & > button {
        display: block;
        margin: 0 1rem 1rem 0;
        padding: 0.5rem;
        background-color: white;
        border: 1px dotted grey;
    }
}

.copying {
    width: 90%;
    height: 500px;
}

.tabs {
    display: flex;
    margin-top: 2rem;

    & > button {
        width: 33%;
        border: 1px solid black;
        background-color: white;
    }

    &Active {
        color: white;
        background-color: forestgreen !important;
    }
}

.itemsByComplexity {
    & > div {
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;

        & > div {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
    }
}