.home {
	position: relative;
	background-color: var(--colorBlueLight);
	background-image: url(/images/clouds.png), url(/images/home-bg.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 33% 22%, center bottom -65px;

	@media (min-height: 700px) {
		background-position: 33% 22%, center bottom;
	}
}

.logo {
	position: relative;
	margin-bottom: var(--spacingMedium);
}

.logoLink {
	position: absolute;
	display: block;
	bottom: 0;
	left: 13%;
	width: 74%;
	height: 1.85rem;
	opacity: 0.5;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -70%);
}

.subtitle {
	padding: 0 var(--spacingMedium);
	margin-bottom: var(--spacingMedium);
	font-family: var(--fontFamilyBase);
	text-transform: initial;
	line-height: 1.2;
	max-width: 278px;
	font-size: var(--fontSizeMedium);
}

.btn {
	margin-bottom: var(--spacingSmall);
	width: 15rem;
}

.cookieChange {
	position: absolute;
	bottom: 0;
	right: 0;
	border: none;
	background: rgba(255, 255, 255, 0.5);
	line-height: 1;
	padding: 0.2rem 0.3rem;
	font-size: 1rem;
}

@media (min-height: 1000px) {
	.logo {
		margin-bottom: var(--spacingLarge);
	}
	.subtitle {
		margin-bottom: var(--spacingLarge);
	}
}
