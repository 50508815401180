.bg {
	background: var(--colorGreenLight) url(../../../images/grass.png);
	background-size: 33%;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;

	h1 {
		font-size: var(--fontSizeXSmall);
		margin-bottom: 5px;
	}

	&Timer {
		font-family: var(--fontFamilyHeading);
		font-feature-settings: 'tnum';
		font-variant-numeric: tabular-nums;
		text-transform: uppercase;
		line-height: 1;
		font-size: var(--fontSizeLarge);
		color: var(--colorGreenDark);

		&Red {
			animation: timerHighlight 1s infinite;
		}

		&Lost {
			color: var(--colorRed);
		}
	}
}

@keyframes timerHighlight {
	0% {
		color: var(--colorRed);
	}
	49% {
		color: var(--colorRed);
	}
	50% {
		color: var(--colorGreenDark);
	}
	100% {
		color: var(--colorGreenDark);
	}
}

.mainWrapper {
	position: relative;
	flex: 1;
}

.game {
	position: relative;

	/*
	pointer events can interfere with the touch events and need to be re-enabled manually
	also focus states do not behave as normal during gameplay and need to be disabled
	*/
	* {
		pointer-events: none;
		&:focus {
			outline: none;
		}
	}

	.pointerEvents {
		pointer-events: all;
	}
}

.board {
	background-color: var(--colorBlueDark);
	background-image: url(../../../images/boot-bottom.png),
		url(../../../images/boot-top.png), url(../../../images/boot-middle.png);
	background-position: bottom left, top left, top left;
	background-repeat: no-repeat, no-repeat, repeat-y;
	background-size: contain;
	padding: 14% 11% 13% 11%;
	margin-top: -20px;
	margin-left: auto;
	margin-right: auto;

	&Inner {
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	&Background {
		&Row {
			display: flex;

			& > div {
				position: relative;

				& > div {
					width: 100%;
					height: 100%;
				}
			}
		}

		&Square {
			background-image: url(../../../images/grid-square-bg.png);
			background-size: 100%;
		}
	}

	&Items {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		& > div {
			position: absolute;
			opacity: 0.8;
		}
	}
}

.item {
	&Image {
		img {
			width: 100%;
		}
	}
	&GridRow {
		display: flex;
	}

	&GridCell {
		box-sizing: border-box;
		border-style: solid;
	}

	&ShapeBlack {
		border-color: var(--colorBlack);
	}

	&ShapeGreen {
		border-color: var(--colorGreen);
	}

	&ShapeRed {
		border-color: var(--colorRed);
	}

	&FillWhite {
		background-color: white;
	}

	&FillGreen {
		background-color: rgba(var(--colorGreenLightRgb), 0.7);
	}

	&FillRed {
		background-color: rgba(var(--colorRedLightRgb), 0.7);
	}
}

.itemPreview {
	position: absolute;
}

.itemRow {
	position: relative;
	display: flex;
	justify-content: center;
	padding: 5% 3%;

	&Item {
		position: relative;
		width: 18%;
		height: 0;
		padding-top: 18%;
		overflow: hidden;

		&:not(:last-child) {
			margin-right: 3%;
		}

		& > button {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			& > div {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

.activeItem {
	opacity: 0.5;
	position: absolute;
}

.activeBoard {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.requirements {
	&Text {
		font-family: var(--fontFamilyHeading);
	}
}

.btnMargin {
	margin-bottom: var(--spacingSmall);
}

.hourglass {
	margin: 0 auto var(--spacingSmall);
}

.wonGame {
	padding-top: 80px;
	top: 53%;
}

.wonHeader {
	border-style: solid;
	border-image: url(../../../images/border-large.png) 20/22px stretch;
	width: fit-content;
	padding: 12px 40px 25px;
	margin: 0 auto;
	position: absolute;
	top: -42px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	border-radius: 60px;

	.wonScore {
		margin-bottom: 10px;
	}
}

.wonTitle {
	margin-bottom: 5px;

	+ p {
		margin-bottom: 10px;
		line-height: 1.2;
	}
}

.wonScore {
	font-family: var(--fontFamilyHeading);
	font-size: var(--fontSizeLarge);
}

.wonScoreComparison {
	span {
		font-family: var(--fontFamilyHeading);
		font-size: 16px;
	}
}

.wonStats {
	width: 80%;
	margin: 0 auto 10px;
	text-align: left;

	p {
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;

		span {
			width: 23%;
			text-align: left;
			font-family: var(--fontFamilyHeading);
			font-size: 16px;
			margin-left: 10px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.wonHigherScore {
	color: var(--colorBurgundy);
}

.shareBottom {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: var(--spacingSmall);

	a {
		display: inline-flex;
	}

	p {
		margin: 0 5px;
	}
}

.shareTop {
	margin-bottom: 5px;

	h2 {
		font-size: 18px;
		margin-bottom: 5px;
	}
}
