.about {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: var(--colorBlueLight);
	background-image: url(/images/clouds.png), url(/images/about-bg.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 0 15%, bottom center;
}

.buttonWrapper {
	position: absolute;
	right: 2rem;
	top: 2.5rem;
}

.title {
	position: absolute;
	top: 120px;
	color: var(--colorGreyDark);
	font-family: var(--fontFamilyBase);
	font-size: 1.375rem;
	max-width: 28ch;

	span {
		display: block;
		color: var(--colorBurgundy);
		font-size: 4.375rem;
		line-height: 1;
	}
}

.link {
	position: absolute;
	bottom: 40px;
}
