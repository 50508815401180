.background {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(044, 48, 53, 0.4);
	margin-top: -4%;
}

.foreground {
	background-color: var(--colorWhite);
	padding: var(--spacingSmall) var(--spacingMedium);
	border-image: url(/images/border--normal.png) 12 / 12px round;
	border-radius: 1.2em;
	border-style: solid;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 335px;
	width: 90%;
	max-width: 406px;
	z-index: 10;

	@media screen and (min-width: 400px) {
		padding: var(--spacingLarge);
	}
}
