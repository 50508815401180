.shareIcon {
	width: 39px;
	height: 39px;
	margin: 0 5px;
}

.shareIconWrap {
	border: none;
	background: none;
	display: inline-block;
	cursor: pointer;
}

.popup {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	margin-top: -70px;
	padding: 10px;
	border-image: url(/images/border--normal.png) 12 / 12px round;
	border-style: solid;
	border-radius: 20px;
	background-color: var(--colorYellowLight);
	white-space: nowrap;
}
