.completed {
	background: var(--colorBlueLight) url(/images/clouds.png) no-repeat 0 15%;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		background: var(--colorGreenLight) url(/images/grass.png) repeat;
		background-size: 33%;
		width: 100%;
		height: 30%;
	}
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	:not(:last-child) {
		margin-bottom: var(--spacingMedium);
	}
}

.buttonWrapper {
	position: absolute;
	right: 2rem;
	top: 2.5rem;
}

.link {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	bottom: 40px;
	z-index: 10;
}
