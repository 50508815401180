.fixed {
	overflow: hidden;
	touch-action: none;
}

.wrapper {
	max-width: 30rem;
	margin: 0 auto;
	overflow: hidden;
	text-align: center;
	display: flex;
	flex-direction: column;
}
