.btn {
	position: relative;
	width: 39px;
	height: 39px;
	border-image: url(/images/border--small.png) 9 / 9px;
	border-style: solid;
	border-radius: 16px;
	padding: 0;
	background-color: var(--colorWhite);
	display: flex;
	align-items: center;
	justify-content: center;
}
